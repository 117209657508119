import React from 'react';
import UserContext from './Helpers/UserContext';
import { useQuery,  useMutation } from '@apollo/client';
import { isLoggedIn } from './Helpers/IsLoggedIn';
import { CURRENT_USER_COURIER, GET_CONFIG } from './GraphQL/Queries';
import {UPDATE_CURRENT_LOCATION} from './GraphQL/Mutations';
import Session from './Helpers/Session';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Loading } from './Components/Loading';
import { Courier } from './types/CurrentCourier';
import {useJsApiLoader } from "@react-google-maps/api";
import UserCurrentLocation from './Helpers/UserCurrentLocation';
import { APIResponse } from './Helpers/General';

const Register = React.lazy(() => import('./Pages/Auth/Register'));
const Login = React.lazy(() => import('./Pages/Auth/Login'));
const Verification = React.lazy(() => import('./Pages/Auth/Verification'));
const Dashboard = React.lazy(() => import('./Pages/MenuPages/Dashboard'));
const Account = React.lazy(() => import('./Pages/MenuPages/Account'));
const TermsAndPolicy = React.lazy(() => import('./Pages/MenuPages/Account/Terms&Policy'));
const ManageVehicle = React.lazy(() => import('./Pages/MenuPages/Account/ManageVehicle'));
const CustomerSupport = React.lazy(() => import('./Pages/MenuPages/Account/CustomerSupport'));
const Wallet = React.lazy(() => import('./Pages/MenuPages/Wallet'));
const Withdraw = React.lazy(() => import('./Pages/MenuPages/Wallet/Withdraw'));
const Transaction = React.lazy(() => import('./Pages/MenuPages/Wallet/Transaction'));
const Notification = React.lazy(() => import('./Pages/MenuPages/Account/Notification'));
const ManageDocuments = React.lazy(() => import('./Pages/MenuPages/Account/ManageDocuments'));
const Order = React.lazy(() => import('./Pages/MenuPages/Order'));

const App: React.FC = () => {
    const [user, setUser] = React.useState<Courier | null>(null);
    const [config, setConfig] = React.useState('{}');
    const loggedIn = React.useMemo(() => isLoggedIn() && user, [user]);
    const [userLocation, setUserLocation] = React.useState<{ latitude: number; longitude: number } | null>(null);

    useQuery(GET_CONFIG, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            setConfig(data.getConfig);
            Session.setCookie('config', JSON.stringify(data.getConfig));
        }
    });

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
        libraries: ['places', 'maps', 'geocoding', 'marker', 'routes',  'places', 'drawing', 'visualization', 'geometry'],
        region: 'NG',
        language: 'en',
        version: 'weekly'
    })

    useQuery(CURRENT_USER_COURIER, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setUser(data.currentUserCourier);
            Session.setCookie('user', JSON.stringify(data.currentUserCourier));
            Session.set("user", data.currentUserCourier)
        }
    });
    const [updateCurrentLocation] = useMutation(UPDATE_CURRENT_LOCATION, {
        onError: (error) => {
            APIResponse(error);
        }
    });

    const updateLocation = React.useCallback(async () => {
        if (loggedIn) {
            try {
                const location = await UserCurrentLocation();
                if (
                    !userLocation ||
                    location.latitude !== userLocation.latitude ||
                    location.longitude !== userLocation.longitude
                ) {
                    setUserLocation(location);
                    await updateCurrentLocation({
                        variables: {
                            lat: location.latitude,
                            lng: location.longitude
                        }
                    });
                }
            } catch (error) {
                console.error("Error updating location:", error);
            }
        }
    }, [loggedIn, userLocation, updateCurrentLocation]);

    React.useEffect(() => {
        updateLocation();
        const intervalId = setInterval(updateLocation, 20000);
        return () => clearInterval(intervalId);
    }, [updateLocation]);
    
    const shouldRedirectToVerify = () => {
        const { nin_status, riders_license_status }: any = user?.courierDocumentStatus || {};
        const { status, bike_model, bike_brand, bike_year, bike_plate_number }: any = user?.courierDetails || {};


        return (
            nin_status !== "APPROVED" ||
            riders_license_status !== "APPROVED" ||
            (status !== "Active" && (!bike_model || !bike_brand || !bike_year || !bike_plate_number))
        );
    };

    if (!loggedIn) {
        return (
            <Router>
                <Routes>
                    <Route path={'/register'} element={<React.Suspense fallback={<Loading />}> <Register /> </React.Suspense>} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
        );
    } else {
        if (!isLoaded) return <Loading />;
        return (
            <UserContext.Provider value={{ user, setUser, config, setConfig }}>
                <Router>
                    <Routes>
                        <Route path={'/'} element={shouldRedirectToVerify() ? <Navigate to="/verify" /> : <React.Suspense fallback={<Loading />}> <Dashboard /> </React.Suspense>} />
                        <Route path={'/verify'} element={<React.Suspense fallback={<Loading />}> <Verification /> </React.Suspense>} />
                        <Route path={'/dashboard'} element={<Navigate to="/" />} />
                        <Route path={'/account'} element={<React.Suspense fallback={<Loading />}> <Account /> </React.Suspense>} />
                        <Route path={'/order'} element={<React.Suspense fallback={<Loading />}> <Order /> </React.Suspense>} />
                        <Route path={'/managedocuments'} element={<React.Suspense fallback={<Loading />}> <ManageDocuments /> </React.Suspense>} />
                        <Route path={'/notification'} element={<React.Suspense fallback={<Loading />}> <Notification /> </React.Suspense>} />
                        <Route path={'/policy'} element={<React.Suspense fallback={<Loading />}> <TermsAndPolicy /> </React.Suspense>} />
                        <Route path={'/managevehicle'} element={<React.Suspense fallback={<Loading />}> <ManageVehicle /> </React.Suspense>} />
                        <Route path={'/support'} element={<React.Suspense fallback={<Loading />}> <CustomerSupport /> </React.Suspense>} />
                        <Route path={'/wallet'} element={<React.Suspense fallback={<Loading />}> <Wallet /> </React.Suspense>} />
                        <Route path={'/wallet/withdraw'} element={<React.Suspense fallback={<Loading />}> <Withdraw /> </React.Suspense>} />
                        <Route path={'/wallet/transaction'} element={<React.Suspense fallback={<Loading />}> <Transaction /> </React.Suspense>} />
                        <Route path="*" element={<Account />} />
                    </Routes>
                </Router>
            </UserContext.Provider>
        );
    }
};

export default App;
